<template>
  <vx-card class="mt-base">
    <div v-if="isEdit">
      <vs-row
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        vs-w="12"
        v-if="!isAU"
      >
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5 mb-0">
            <vs-checkbox class="w-full" v-model="data.defaultRate"
              >Default rate is 0</vs-checkbox
            >
          </div>
        </vs-col>
      </vs-row>
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5 mb-0">
            <vs-input
              style="padding-right: 25px !important"
              :success="!errors.first('clinicDoctorDefaultRate')"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('clinicDoctorDefaultRate') ? true : false"
              v-validate="{
                required: true,
                regex: /^\d*\.?\d*$/,
              }"
              name="clinicDoctorDefaultRate"
              data-vv-as="Doctor Default Rate for Clinic"
              label="Doctor Default Rate for Clinic ($)"
              icon-no-border
              icon="icon icon-dollar-sign"
              icon-pack="feather"
              class="w-full"
              :danger-text="errors.first('clinicDoctorDefaultRate')"
              v-model="data.clinicDoctorDefaultRate"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        vs-w="12"
        style="display: none"
      >
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5 mb-0">
            <vs-input
              style="padding-right: 25px !important"
              :success="!errors.first('doctorDefaultRate')"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('doctorDefaultRate') ? true : false"
              v-validate="{
                required: true,
                regex: /^\d*\.?\d*$/,
              }"
              name="doctorDefaultRate"
              data-vv-as="Doctor Default Rate for Freshclinic"
              label="Doctor Default Rate for Freshclinic ($)"
              icon-no-border
              icon="icon icon-dollar-sign"
              icon-pack="feather"
              class="w-full"
              :danger-text="errors.first('doctorDefaultRate')"
              v-model="data.doctorDefaultRate"
            />
          </div>
        </vs-col>
      </vs-row>

      <vs-row
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        vs-w="6"
        style="
          border: 1px solid rgb(221, 221, 221);
          margin-top: 15px;
          margin-bottom: 15px;
          padding: 0 0 20px 0;
          border-radius: 10px;
        "
      >
        <span
          style="
            padding: 0px 10px;
            background: #fff;
            position: absolute;
            top: 190px;
            margin-left: 12px;
          "
          >Company Details</span
        >
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5 mb-0">
            <vs-input
              style="padding-right: 25px !important"
              :success="!errors.first('companyTitle')"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('companyTitle') ? true : false"
              v-validate="{
                required: true,
              }"
              name="companyTitle"
              data-vv-as="Company Title"
              label="Company Title"
              icon-no-border
              icon="icon icon-file-text"
              icon-pack="feather"
              class="w-full"
              :danger-text="errors.first('companyTitle')"
              v-model="data.companyTitle"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5 mb-0">
            <vs-input
              style="padding-right: 25px !important"
              :success="!errors.first('companyName')"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('companyName') ? true : false"
              v-validate="{
                required: true,
              }"
              name="companyName"
              data-vv-as="Company Name"
              label="Company Name"
              icon-no-border
              icon="icon icon-layers"
              icon-pack="feather"
              class="w-full"
              :danger-text="errors.first('companyName')"
              v-model="data.companyName"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5 mb-0">
            <vs-input
              style="padding-right: 25px !important"
              :success="!errors.first('companyAddress')"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('companyAddress') ? true : false"
              v-validate="{
                required: true,
              }"
              name="companyAddress"
              data-vv-as="Company Address"
              label="Company Address"
              icon-no-border
              icon="icon icon-navigation"
              icon-pack="feather"
              class="w-full padded-input"
              :danger-text="errors.first('companyAddress')"
              v-model="data.companyAddress"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5 mb-0">
            <vs-input
              style="padding-right: 25px !important"
              :success="!errors.first('companyAbn')"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('companyAbn') ? true : false"
              v-validate="{
                required: true,
              }"
              name="companyAbn"
              data-vv-as="Company ABN"
              label="Company ABN"
              icon-no-border
              icon="icon icon-briefcase"
              icon-pack="feather"
              class="w-full"
              :danger-text="errors.first('companyAbn')"
              v-model="data.companyAbn"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5 mb-0">
            <vs-input
              style="padding-right: 25px !important"
              :success="!errors.first('companyBankAccountNumber')"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('companyBankAccountNumber') ? true : false"
              v-validate="{
                required: true,
              }"
              name="companyBankAccountNumber"
              data-vv-as="Company Bank Account Number"
              label="Company Bank Account Number"
              icon-no-border
              icon="icon icon-home"
              icon-pack="feather"
              class="w-full"
              :danger-text="errors.first('companyBankAccountNumber')"
              v-model="data.companyBankAccountNumber"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12" v-if="!isAU">
          <div class="w-full m-5 mb-0">
            <vs-input
              style="padding-right: 25px !important"
              :success="!errors.first('companyContactNumber')"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('companyContactNumber') ? true : false"
              v-validate="{
                required: true,
              }"
              name="companyContactNumber"
              data-vv-as="Company Contact Number US"
              label="Company Contact Number US"
              icon-no-border
              icon="icon icon-home"
              icon-pack="feather"
              class="w-full"
              :danger-text="errors.first('companyContactNumber')"
              v-model="data.companyContactNumber"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12" v-else>
          <div class="w-full m-5 mb-0">
            <vs-input
              style="padding-right: 25px !important"
              :success="!errors.first('companyContactNumber')"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('companyContactNumber') ? true : false"
              v-validate="{
                required: true,
              }"
              name="companyContactNumber"
              data-vv-as="Company Contact Number AU"
              label="Company Contact Number AU"
              icon-no-border
              icon="icon icon-home"
              icon-pack="feather"
              class="w-full"
              :danger-text="errors.first('companyContactNumber')"
              v-model="data.companyContactNumber"
            />
          </div>
        </vs-col>

      </vs-row>

      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full ml-5">
            <vs-button
              :disabled="!validateForm"
              @click="saveData"
              icon-pack="feather"
              class="mr-2"
              >Save</vs-button
            >

            <vs-button
              color="danger"
              @click="cancelEdit"
              icon-pack="feather"
              class="mr-2"
              >Cancel</vs-button
            >
          </div>
        </vs-col>
      </vs-row>
    </div>

    <div v-else>
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5 mb-0">
            <label for>
              <strong>Doctor Default Rate for Clinic ($)</strong>
            </label>
            <p>{{ data.clinicDoctorDefaultRate || "N/A" }}</p>
          </div>
        </vs-col>
      </vs-row>

      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5 mb-0">
            <label for>
              <strong>Doctor Default Rate for Freshclinic ($)</strong>
            </label>
            <p>{{ data.doctorDefaultRate || "N/A" }}</p>
          </div>
        </vs-col>
      </vs-row>

      <vs-row
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        vs-w="12"
        style="margin-bottom: 20px"
      >
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5 mb-0">
            <label for>
              <strong>Restrict Contractor Doctor On Sunday</strong>
            </label>
            <vs-switch
              v-if="!restrictContractorDoctorOnSundayToggled"
              color="success"
              id="userStatusLoader"
              @click="toggleRestrictContractorDoctorOnSunday()"
              class="m-0"
              v-model="data.restrictContractorDoctorOnSunday"
            />
            <div
              v-if="restrictContractorDoctorOnSundayToggled"
              style="width: 25px; height: auto; display: flex"
            >
              <span style="margin-right: 5px">Processing</span>

              <img
                src="../../../public/loader.gif"
                style="width: 100%; height: 100%"
              />
            </div>
          </div>
        </vs-col>
      </vs-row>

      <vs-row
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        vs-w="6"
        style="
          border: 1px solid rgb(221, 221, 221);
          margin-top: 15px;
          margin-bottom: 15px;
          padding: 0 0 20px 0;
          border-radius: 10px;
        "
      >
        <span
          style="
            padding: 0px 10px;
            background: #fff;
            position: absolute;
            top: 205px;
            margin-left: 12px;
          "
          >Company Details</span
        >

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5 mb-0">
            <label for>
              <strong>Company Title</strong>
            </label>
            <p>{{ data.companyTitle || "N/A" }}</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5 mb-0">
            <label for>
              <strong>Company Name</strong>
            </label>
            <p>{{ data.companyName || "N/A" }}</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5 mb-0">
            <label for>
              <strong>Company Address</strong>
            </label>
            <p>{{ data.companyAddress || "N/A" }}</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5 mb-0">
            <label for>
              <strong>Company ABN</strong>
            </label>
            <p>{{ data.companyAbn || "N/A" }}</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5 mb-0">
            <label for>
              <strong>Company Bank Account Number</strong>
            </label>
            <p>{{ data.companyBankAccountNumber || "N/A" }}</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5 mb-0">
            <label for>
              <strong>Company Contact Number</strong>
            </label>
            <p>{{ data.companyContactNumber || "N/A" }}</p>
          </div>
        </vs-col>
      </vs-row>

      <vs-row
        v-if="check_has_permission('updateSettings')"
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        vs-w="12"
      >
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full ml-5">
            <vs-button @click="editData" icon-pack="feather" class="mr-2"
              >Update</vs-button
            >
          </div>
        </vs-col>
      </vs-row>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AdminSettings",
  data() {
    return {
      isEdit: false,
      restrictContractorDoctorOnSundayToggled: false,
      data: {
        clinicDoctorDefaultRate: 0,
        doctorDefaultRate: 0,
        companyTitle: "",
        companyName: "",
        companyAddress: "",
        companyAbn: "",
        companyBankAccountNumber: "",
        companyContactNumber: "",
        restrictContractorDoctorOnSunday: false,
        defaultRate: false,
        isAU: true,
      },
    };
  },
  methods: {
    ...mapActions("admin", ["getSettings", "saveSettings"]),
    async getExistingSettings() {
      this.$vs.loading();
      await this.getSettings()
        .then((result) => {
          this.data = result.data.data;
          if (
            !this.data.hasOwnProperty("restrictContractorDoctorOnSunday") ||
            this.data.restrictContractorDoctorOnSunday === "false"
          )
            this.data.restrictContractorDoctorOnSunday = false;
          else this.data.restrictContractorDoctorOnSunday = true;

          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Error",
            text: "Error fetching current rate",
            color: "danger",
          });
          this.$vs.loading.close();
        });
    },
    editData() {
      this.isEdit = true;
    },
    async cancelEdit() {
      await this.getExistingSettings();
      this.isEdit = false;
    },
    toggleRestrictContractorDoctorOnSunday() {
      this.data.restrictContractorDoctorOnSunday =
        !this.data.restrictContractorDoctorOnSunday;
      this.restrictContractorDoctorOnSundayToggled = true;

      let saveData = {
        restrictContractorDoctorOnSunday:
          this.data.restrictContractorDoctorOnSunday,
      };

      this.saveSettings(saveData)
        .then(() => {
          this.restrictContractorDoctorOnSundayToggled = false;
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Error",
            text: "Error saving data",
            color: "danger",
          });
          this.restrictContractorDoctorOnSundayToggled = false;
          this.data.restrictContractorDoctorOnSunday =
            !this.data.restrictContractorDoctorOnSunday;
        });
    },
    saveData() {
      this.$vs.loading();
      let saveData = {
        clinicDoctorDefaultRate: this.data.clinicDoctorDefaultRate,
        doctorDefaultRate: this.data.doctorDefaultRate,
        companyTitle: this.data.companyTitle,
        companyName: this.data.companyName,
        companyAddress: this.data.companyAddress,
        companyAbn: this.data.companyAbn,
        companyBankAccountNumber: this.data.companyBankAccountNumber,
        companyContactNumber: this.data.companyContactNumber,
        defaultRate: this.data.defaultRate,
      };
      this.saveSettings(saveData)
        .then((result) => {
          this.$vs.notify({
            title: "Success",
            text: "Setting saved successfully",
            color: "success",
          });
          this.$vs.loading.close();
          this.isEdit = false;
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Error",
            text: "Error saving setting",
            color: "danger",
          });
          this.$vs.loading.close();
        });
    },
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  mounted() {},
  async created() {
    await this.getExistingSettings();
    this.isAU = process.env.VUE_APP_REGION === "AU" ? true : false;
  },
};
</script>

<style scoped>
/* .vs-inputx.vs-input--input {
  background: olivedrab !important;
} */
</style>
